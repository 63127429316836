@use "sass:math";
@use "variables" as *;
@use "mixins" as *;

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  width: auto;
  padding: math.div($spacing-unit, 2);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &.imageboard, &.photoboard {
    width:100%;
  }
  @include media-query($on-palm) {
    padding-right: $spacing-unit;
    margin-left: 350px;
    &.photoboard {
      margin-left: 120px;
    }
    p {
      font: 400 15px/1.5 "courier_prime_coderegular", Courier, monospace;
      padding-bottom: 1em;
    }
    ul {
      padding-left: $spacing-unit;
    }
  }

}

body {
  display: flex;
}

ol, ul {
  margin: 0;
}

.thumbnail {
  position: relative;
  margin-bottom: 20px;
  .thumbnail-title a {
    background-color: rgba($color: #000000, $alpha: 0.7);
    color: #fff;
    top: 0;
    width: 100%;
    position: absolute;
    padding-left: 5px;
    padding-right: 5px;
  }
  @include media-query($on-palm) {
    margin: auto;
  }
}

table {
  td {
    vertical-align: middle;
    border-left: 1px solid #d2d2d22f;
    padding-left: 5px;
    padding-right: 5px;
  }
  tr:nth-child(even) {
    background-color: #d2d2d22f;
  }
}
