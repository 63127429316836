@use "../variables" as v;
.summer {
    .container {
        color: v.$charcoal;
        background-image: url('https://wileywiggins.com/images/garden2x3.jpg');
        background-repeat: repeat-y;
        p {
            padding: 10px;
        }
    }
    
}