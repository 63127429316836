@use "../variables" as v;

body.p5sketch {
  canvas {
    width: 100%;
  }
  background-color: v.$charcoal;
  color: v.$paper;
  a {
    color: v.$paper;
  }
  .content {
    background-color: #ccc;
    padding: 10px;
    color: v.$charcoal;
    a {
      color: #000;
    }
  }
  svg.logo {
    width: auto;
    height: 120px;
    path {
      fill: v.$paper;
    }
  }
}
