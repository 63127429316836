body.about {
  .text-navigation {
    a.page-link.about {
      background-color: #33312b;
      color: #f9f7f3;
      font-weight: bold;
    }
  }
  .container {
    max-width: 800px;
  }
}
