@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(dropIn) {
  0% {
    opacity: 0;
    @include helpers.u-transform(translateY(v.$saf-drop-rise-distance * -1) rotate(0) translateZ(0));
  }
  10% {
    @include helpers.u-transform(translateY(v.$saf-drop-rise-distance * -1) rotate(-40deg));
  }
  90% {
    opacity: 1;
  }
  100% {
    @include helpers.u-transform(translateY(0) rotate(0));
  }
}

@mixin dropIn($duration: 0.5s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(dropIn $duration $delay $fill-mode);
}
