
body.our-water-is-critical-infrastructure {
    .container {
        margin-left: auto;
        margin-right: auto;
    }
    background-color: #fff;
    color: #111;
    section {
        background-color: #fff;
        a { color: #000;}
    }
    h1, h2 {
        font-family: 'shaimusrough';
    }
    h2 {
        font-size: 60px;
        text-align: center;
        margin-bottom: 10px;;
    }
    .post {
        color: #fff;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        a {
            color: #fff;
        }
        background-color: #111;
    }
}