@use "sass:math";
@use "sass:color";
@use "variables" as *; 
@use "mixins" as *;

body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;

  @media (prefers-color-scheme: dark) {
    color: $background-color;
    background-color: $text-color;
  }
  @include media-query($on-laptop) { 
    padding: 1em;
  }
}

pre,
code {
  font-family: "courier_prime_coderegular", Courier, monospace;
  white-space: pre-wrap;
  line-break: auto;
  word-wrap: break-word;
  box-sizing: border-box;
}


.site-header,
sidebar {
  a {
    text-decoration: none;
  }
}

/**
 * Images
 */
img {
  max-width: 100%;
}

.headshot {
  display: block;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
}

/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

/**
 * Lists
 */
li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}

/**
 * Paragraph indentations
 */
.post-content p {
  margin-top: 0.5em;
}

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headline-font-family;
}

/**
 * Links
 */
a {
  color: $brand-color;

  // Replace deprecated darken() / saturate() with color.adjust()
  &:visited {
    color: color.adjust($brand-color, $lightness: -15%);
  }

  &:hover {
    color: color.adjust($brand-color, $saturation: 20%);
  }

  @media (prefers-color-scheme: dark) {
    color: $background-color;

    &:hover {
      color: color.adjust($background-color, $lightness: -10%);
    }

    &:visited {
      color: $background-color;
    }
  }
}

/**
 * Blockquotes
 */
blockquote {
  margin: 28px;
  border-left: 4px solid $grey-color-light;
  padding-left: math.div($spacing-unit, 2); // replaced "/" with math.div()
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic;

  @media (prefers-color-scheme: dark) {
    color: $pino;
  }

  > :last-child {
    margin-bottom: 0;
  }

  .p {
    font-size: inherit;
    font-style: italic;
  }
}

hr {
  width:100%;
}

body.project {
  blockquote {
    color: $pino;
  }
}

/**
 * Wrapper
 */
.wrapper {
  // Because these are multiplications inside calc(), it’s still valid.
  min-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  min-width: calc(#{$content-width} - (#{$spacing-unit} * 2));

  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;

  @extend %clearfix;

  @include media-query($on-laptop) {
    min-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    min-width: calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: math.div($spacing-unit, 2); // replaced "/" with math.div()
    padding-left: math.div($spacing-unit, 2);  // replaced "/" with math.div()
  }
}

/**
 * Clearfix
 */
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
