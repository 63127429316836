@use "../variables" as *;
@use "../mixins" as *;
@use "buttons" as *;

ul.tagcloud {
    display: flex;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 1rem;
    margin-bottom: 20px;
    li {
        flex: 1 auto;
        text-align: center;
        padding: .1rem;
        .btn {
            @include button;
        }
    }
}

li.filterable {
    display: none;
    &.show {
        display: block;
    }
}
