@use "../variables" as v;

body.building-block {

  .text-navigation {
    a.page-link.building-block {
      background-color: v.$charcoal;
      color: v.$paper;
      font-weight: bold;
    }
  }

  .page {
    color: v.$charcoal; 
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }

  .pink {

    color: #f99;
  }

  .cube-container {
    margin-top: 20vh;
    margin-left: 10vh;
  }

  .box-wrapper {
    perspective: 800px;
    perspective-origin: 50% 100px;
  }

  .box-faces {
    position: relative;
    width: 400px;
    transform-style: preserve-3d;
  }

  .box-face {
    outline: 1px solid #f99; 
    position: absolute;
    width: 400px;
    height: 400px;
    opacity: 0.98;
  }

  .box-face--is-back {

    background-color: v.$paper;
    transform: translateZ(-200px) rotateY(180deg);
  }

  .box-face--is-right {

    background-color: #ddd;
    transform: rotateY(-270deg) translateX(200px);
    transform-origin: top right;
  }

  .box-face--is-left {
    background-color: #ddd;
    transform: rotateY(270deg) translateX(-200px);
    transform-origin: center left;
  }

  .box-face--is-top {

    background-color: #fafafa;
    transform: rotateX(-90deg) translateY(-200px);
    transform-origin: top center;
  }

  .box-face--is-bottom {

    background-color: #bbb;
    transform: rotateX(90deg) translateY(200px);
    transform-origin: bottom center;
  }

  .box-face--is-front {
    background-color: v.$paper;
    transform: translateZ(200px);
  }

  @keyframes spin {
    from {
      transform: rotateY(0);
    }
    to {
      transform: rotateY(360deg);
    }
  }

  .box-faces,
  .shadow {
    animation: spin 15s infinite linear;
  }

  .icon-rocket {

    color: #4f5d75;
  }

  .shadow-wrapper {
    perspective: 800px;
    perspective-origin: 50% 100px;
  }

  .shadow {
    position: relative;
    width: 400px;
    transform-style: preserve-3d;

    div {
      position: absolute;
      width: 400px;
      height: 400px;
      top: 0px;
      opacity: 0.98;
      box-shadow: 0px 0px 100px #000;
      transform: rotateX(90deg) translateY(200px);
      transform-origin: bottom center;
    }
  }
}
