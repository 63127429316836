@use "../variables" as *;
@use "../mixins" as *;

model-viewer {
    width: 300px;
    height: 400px;
    @include media-query($on-palm){
        width: 100%;
    }
}


.grid.models {
    * {
        box-sizing: border-box;
    }
    .grid-sizer, .grid-item {
        width: 100%;
        @include media-query($on-laptop){
            width: 50%;
        }
        
    }
    .iframe-container {
        @include media-query($on-palm){
            width: auto;
        }
    }

    .grid-item {
        > div{
            margin: 10px;
        }
    
        p {
            padding: $spacing-unit;
            background-color: rgba(0, 0, 0, 0.5);
            display: block;
            margin-top: 0px;
        }
    }
    
}

span.model-name {
    display: block;
    position: absolute;
    bottom: -24px;
    bottom: 0px;
    background-color: rgba($color: #000000, $alpha: .8);
    padding: 4px;
    color: #fff;
    a {
        color: #fff;
        

    }
}
