@use "../variables" as *;
@use "../mixins" as *;
@use "buttons" as *;
@use "sass:color";

$event-color: #ff7979;
$event-link-color: #feff00;
$event-link-hover-color: color.adjust($event-link-color, $saturation: 10%);

.event {
    @include dropIn(300ms);
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
    top: -80px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    background-color: $translucent-ocean;
    border-radius: 0px 0px 3px 3px;
    padding: 10px;
    max-width: 400px;
    min-height: 30px;
    box-sizing: border-box;
    @include media-query($on-palm) {
        top: -95px;  
    }
    
}

.event .label {
    a {
      color: $paper;
    }
    &:before {
        font-family: Menlo, monospace;
        content: "📅";
        margin-right: 3px;
        color: transparent;
        text-shadow: 0 0 0 white;
    }
}
