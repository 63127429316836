@use "../variables" as *;
@use "../mixins" as *;
@use "sass:color"; // Importing the color module for color manipulation functions

@mixin button {
  webkit-appearance: none;
  display: block;
  background-color: $slate;
  text-decoration: none;
  padding: 0.2rem 1rem;
  border-radius: 3px;
  color: $mahogany;
  width: max-content;
  margin: auto;
  cursor: pointer;
  font-family: $base-font-family;
  &:hover {
    // Using color.adjust() to change the lightness
    background-color: color.adjust($slate, $lightness: 10%);
    text-decoration: none;
  }
}

button, input[type="submit"], input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  @include button;
}

.button {
  border-radius: 0.3em;
  border: 1px solid $peach;
  display: inline-block;
  margin: 10px 0;
  padding: 0.5em 0.75em;
  cursor: pointer;
  color: $charcoal;
}

a.button:hover, #post-nav a:hover {
  background: $peach;
  border: 1px solid $peach;
  color: #fff;
  text-decoration: none;
}

article a:hover, .posts .post-teaser p a:hover {
  text-decoration: underline;
  color: $marigold;
}

a:active, a:hover {
  outline: 0;
}

a.no-highlight {
  text-decoration: none;
  outline: none;
  cursor: help;
}
