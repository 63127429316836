@use "../variables" as v;
@use "../mixins" as mx;

body.projecthome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(180deg, #d1e6e1, #c6c6c6, #d9a971, #71c1d9);
  background-size: 800% 800%;
  -webkit-animation: LightAnimation 30s ease infinite;
  -moz-animation: LightAnimation 30s ease infinite;
  animation: LightAnimation 30s ease infinite;

  .text-navigation {
    // can stay nested
    &:hover {
      height: 480px;
      background-color: rgba(249, 247, 243, 0.8);
    }
    justify-self: center; // this is just a property, can remain here
  }

  .grid {
    display: none;
  }

  // Step 2: Nested dark-mode rule AFTER main declarations
  @media (prefers-color-scheme: dark) {
    color: v.$background-color;
    background: linear-gradient(180deg, #004533, #001145, #25877a);
    background-size: 600% 600%;
    -webkit-animation: DarkAnimation 30s ease infinite;
    -moz-animation: DarkAnimation 30s ease infinite;
    animation: DarkAnimation 30s ease infinite;

    .text-navigation {
      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
    a {
      color: v.$background-color;
      &:visited {
        color: v.$background-color;
      }
    }
  }

  // Step 3: Nested media query rule AFTER main declarations
  @include mx.media-query(v.$on-palm) {
    display: block;
    background: v.$charcoal;

    * {
      box-sizing: border-box;
    }
    .grid {
      display: block;
    }
    .text-navigation {
      margin-top: 0px;
      position: fixed;
      z-index: 100;
      height: 100px;
      transition: background-color 0.5s, height 0.5s;
    }
    .event {
      z-index: 100;
      margin-right: 10px;
    }
  }
}
