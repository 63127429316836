@use "../variables" as v;     // for $charcoal, $paper, etc.
@use "../mixins" as mx;       // if you still need other mixins like media-query()
@use "../saffron/saffron" as saff; // to access fadeInDown, popIn, etc.

body.obelisk-show {
  color: v.$paper; // Replaces #fff with your Sass variable

  h1 {
    font-size: 18px;
    letter-spacing: 10em;
    text-align: center;
    padding-bottom: 20px;
  }

  .abstract {
    max-width: 400px;
    margin: auto;
    margin-top: 40px;
  }

  padding: 10px;
  margin: 0;

  // Keep or replace the gradient with variables if desired
  background: rgb(20, 6, 40);
  background: linear-gradient(
    180deg,
    rgba(20, 6, 40, 1) 0%,
    rgba(5, 5, 33, 1) 35%,
    rgba(0, 0, 0, 1) 79%
  );
  transition: background-color 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .obelisk-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  section.objects {
    width: 300px;
    margin-right: auto;
    margin-left: auto;

    @include mx.media-query(v.$on-palm) {
      width: 600px;
    }
  }

  .object {
    text-align: center;
    margin-bottom: 100px;

    a {
      display: inline-block;
      padding: 20px;
      background-color: v.$charcoal; // replaces black
      border: 1px solid v.$paper;
      border-radius: 4px;
      color: v.$paper; // replaces #fff
    }
  }

  model-viewer {
    &:focus,
    &:webkit-direct-focus {
      outline: 1px dotted v.$paper;
    }

    @include mx.media-query(v.$on-palm) {
      height: 700px;
    }
  }

  footer {
    img {
      width: 30px;
      float: right;
      display: block;
    }
  }

  .more {
    opacity: 0;
    top: 90vh;
    color: rgb(106, 106, 106);
    right: 40px;

    @include mx.media-query(v.$on-palm) {
      position: absolute;
      // Call fadeInDown from saffron:
      @include saff.fadeInDown(1s, 4s, forwards);
    }
  }
}
