body.animation {
  .text-navigation {
    a.page-link.animation {
      background-color: #33312b;
      color: #f9f7f3;
      font-weight: bold;
    }
  }
  .thumbnail {
    max-height: 310px;
    max-width: 600px;
    overflow: hidden;
  }
  
}
