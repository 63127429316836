@use "../variables" as *;

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 2rem 0;
    flex-wrap: wrap;
    background-color: rgba(0,0,0,0.5);
    color: #fff;
  }
  
  .pagination a,
  .pagination span {
    padding: 0.5rem 1rem;
    
  }
  
  .pagination .current-page {
    border: 1px solid #fff;
  }
  
  .pagination .page-ellipsis {
    padding: 0.5rem;
  }
  

  
  .page-info {
    text-align: center;
    margin-bottom: 2rem;
  }
