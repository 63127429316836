@use "../variables" as *;

#lightbox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 9999999;
  line-height: 0;
  cursor: pointer;
  display: none;

  .img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;

    img {
      opacity: 0;
      pointer-events: none;
      width: auto;
    }
  }

  @media screen and (min-width: 1200px) {
    .img {
      max-width: 1200px;
    }
  }

  @media screen and (min-height: 1200px) {
    .img {
      max-height: 1200px;
    }
  }

  span {
    display: block;
    position: fixed;
    bottom: 13px;
    height: 1.5em;
    line-height: 1.4em;
    width: 100%;
    text-align: center;
    color: white;
    text-shadow:
      -1px -1px 0 #000,
      1px -1px 0 #000,
      -1px 1px 0 #000,
      1px 1px 0 #000;  
    display: none;
  }

  .videoWrapperContainer {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 900px;
    max-height: 100%;

    .videoWrapper {
      height: 0;
      line-height: 0;
      margin: 0;
      padding: 0;
      position: relative;
      padding-bottom: 56.333%; // custom
      background: black;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        display: block;
      }
    }
  }

  #prev, #next {
    height: 50px;
    line-height: 36px;
    display: none;
    margin-top: -25px;
    position: fixed;
    top: 50%;
    padding: 0 15px;
    cursor: pointer;
    text-decoration: none;
    z-index: 99;
    color: white;
    font-size: 60px;
  }

  .gallery #prev, .gallery #next {
    display: block;
  }

  #prev { left: 0; }
  #next { right: 0; }

  #close {
    height: 50px;
    width: 50px;
    position: fixed;
    cursor: pointer;
    text-decoration: none;
    z-index: 99;
    right: 0;
    top: 0;

    &:before, &:after {
      position: absolute;
      margin-top: 22px;
      margin-left: 14px;
      content: "";
      height: 3px;
      background: white;
      width: 23px;
      transform-origin: 50% 50%;
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }

  // Disabling user selection for all child elements
  &,
  * {
    user-select: none;
  }
}
