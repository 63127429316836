@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(stretch) {
  0% {
    @include helpers.u-transform(scaleY(0));
  }
  40% {
    @include helpers.u-transform(scaleY(v.$saf-stretch-compress-exageration));
  }
  60% {
    @include helpers.u-transform(scaleY(v.$saf-stretch-compress-exageration - (v.$saf-stretch-compress-exageration * .075)));
  }
  80% {
    @include helpers.u-transform(scaleY(v.$saf-stretch-compress-exageration));
  }
  100% {
    @include helpers.u-transform(scaleY(v.$saf-stretch-compress-exageration - (v.$saf-stretch-compress-exageration * .075)));
  }
  80% {
    @include helpers.u-transform(scaleY(v.$saf-stretch-compress-exageration - (v.$saf-stretch-compress-exageration * .025)));
  }
  100% {
    @include helpers.u-transform(scaleY(1));
  }
}

@mixin stretchUp($duration: 1.75s, $delay: 0s, $count: 1, $fill-mode: both, $timing: ease-out) {
  @include helpers.u-animation(stretch $count $duration $delay $fill-mode $timing);
  @include helpers.u-prefix(u-transform-origin, 0 100%, webkit moz spec);
}

@mixin stretchDown($duration: 1.75s, $delay: 0s, $count: 1, $fill-mode: both, $timing: ease-out) {
  @include helpers.u-animation(stretch $count $duration $delay $fill-mode $timing);
  @include helpers.u-prefix(u-transform-origin, 0 0, webkit moz spec);
}
