@use "../variables" as *;
@use "../mixins" as *;

.projectgrid {

  @media screen and (max-width: 768px){
    img{
      display: none;
    }
  }
  .grid-item {
    width: 100%;
  }

  @include media-query($on-palm) {
    .grid-item {
      max-width: 25%
    }
  
    grid-item--width2 {
      max-width: 50%;
    }
    
  }

  .grid-item {
      .item-contents {
        display: block;
        position: relative;
      }
      .page-name, .model-name {
        display: block;
        position: absolute;
        bottom: -24px;
        bottom: 0px;
        background-color: rgba($color: #000000, $alpha: .8);
        padding: 4px;
        color: #fff;
        a {
          color: #fff;
          

        }
        img {
          height: 25%;
        }
      }
  }

}
