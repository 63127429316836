
@font-face {
    font-family: 'fjalla_oneregular';
    src: url('/fonts/fjallaone-regular-webfont.woff2') format('woff2'),
         url('/fonts/fjallaone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body.design-page {
    
    display: flex;
    flex-direction: row !important;
    font-family: c_serif_t, c_serif_sil, 'Garamond', serif;
    font-kerning: normal;
    font-feature-settings: "kern","liga","calt";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: optimizeLegibility;
    flex-direction: column;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    height: 100vh;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    // Headings
    h1, h2 {
        font-family: 'fjalla_oneregular', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Helvetica, Arial, sans-serif
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
    }

    h1 { font-size: 2.5rem; }
    h2 { font-size: 2rem; }
    h3 { font-size: 1.75rem; }
    h4 { font-size: 1.5rem; }
    h5 { font-size: 1.25rem; }
    h6 { font-size: 1rem; }


    p {
        margin-bottom: 1rem;
    }

    ul, ol {
        padding-left: 20px;
        li {
            line-height: 1.6;
        }
    }
    
    * {
        box-sizing: border-box;
    }
}
