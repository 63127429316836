@use "../variables" as *;
@use "../mixins" as *;

$text-nav-list-height: 282px;

.title-event {
  .title {
    top: 68px;
  }
}

@media (prefers-color-scheme: dark) {
  path {
    fill: $background-color;
  }
}

.text-navigation {
  width: 100%;
  max-width: 420px;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  background-color: rgba(249, 247, 243, 0.9);
  border-radius: 5px;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: 5px;
  font-weight: 400; // Updated from "weight: 400;"

  .event {
    display: none;
  }

  li {
    a {
      padding: 2px;
      &:hover,
      &.focus &.selected {
        border: none;
        background-color: $charcoal;
        color: $paper;
        @media (prefers-color-scheme: dark) {
          background-color: $paper;
          color: $charcoal;
        }
      }
    }
    &.glyph {
      font-family: Menlo, monospace;
      a {
        font-family: $base-font-family;
        font-size: 1em;
        letter-spacing: -0.05em;
      }
    }
  }

  ol,
  ul {
    margin: 0;
    height: $text-nav-list-height;
    min-height: $text-nav-list-height;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  ul {
    list-style-type: none;
  }

  input {
    line-height: 32px;
    padding: 2px;
    font-size: 16px;
    box-sizing: border-box;
    max-width: 420px;
    width: 100%;
    font-family: $base-font-family;
    border: 1px solid $charcoal;
    background-color: rgba(255, 255, 255, 0.4);
    @media (prefers-color-scheme: dark) {
      color: $paper;
      background-color: rgba(0, 0, 0, 0.2);
      border: 1px solid $paper;
    }
  }

  @media (prefers-color-scheme: dark) {
    color: $paper;
    background-color: $translucent-ocean;
    a {
      color: $paper;
      &:visited {
        color: $paper;
      }
    }
  }
}

.drawing-nav {
  li {
    a {
      padding: 2px;
      &:hover,
      &.focus &.selected {
        border: none;
        background-color: $charcoal;
        color: $paper;
        font-weight: bold;
      }
    }
  }
}
