@use "sass:color";    
@use "../variables" as v; 
@use "../mixins" as mx;

@mixin project-section {
  background-color: color.adjust(v.$charcoal, $lightness: -5%);
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 20px;
  box-sizing: border-box;
}

body.project {

  background-color: v.$charcoal;
  color: v.$paper;

  p,
  ul {
    max-width: 820px;
    margin: auto;
  }

  .fa-ul > li {
    margin-left: 10px;
  }

  .grid.videos {
    .grid-sizer,
    .grid-item {
      width: 100%;
      @include mx.media-query(v.$on-laptop) {
        width: 50%;
      }
    }

    .iframe-container {
      box-sizing: border-box;
      @include mx.media-query(v.$on-palm) {
        width: auto;
      }
      
    }

    .grid-item {
      > div {
        margin: 10px;
      }

      p {
        padding: v.$spacing-unit;
        background-color: rgba(0, 0, 0, 0.5);
        display: block;
        margin-top: 0px;
      }
    }
  }

  #abstract {
    p {
      padding: 1em;
    }

    // Replacing $charcoal with v.$charcoal
    p:nth-child(odd) {
      background-color: v.$charcoal;
    }

    // Using lighten on v.$charcoal
    p:nth-child(even) {
      background-color: color.adjust(v.$charcoal, $lightness: 20%);
    }

    .footnotes {
      margin-top: 1em;
    }

    .footnotes p {
      background-color: none;
      padding: 0;
    }
  }

  video {
    width: 100%;
  }

  a {
    color: v.$paper;
  }

  svg.logo {
    width: auto;
    height: 120px;
    path {
      fill: v.$paper;
    }
  }

  .post {
    background-color: color.adjust(v.$charcoal, $lightness: -5%);
    border-radius: 12px;
    padding: 1rem;
  }

  section,
  .post {
    // Using the local mixin defined above
    @include project-section;

    h2 {
      margin-top: 0;
    }
  }

  .post {
    padding-bottom: 40px;
  }

  section.links {
    transition: all 0.5s ease;

    ul.tagcloud {
      border: 1px solid v.$slate; // or #8C8A87 if you prefer
      border-radius: 6px;
    }
  }

  .lazyloading {
    background: #f7f7f7
      url("//afarkas.github.io/lazysizes/assets/imgs/loader.gif") no-repeat center;
    min-height: 60px;
  }

  summary {
    cursor: pointer;
  }

  details {
    padding-left: 1.5vw;
    display: block;
  }
}

section.files {
  li:before {
    content: none;
  }
}

section.image-directory {
  min-height: 200px;
}
