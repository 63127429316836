@use "../variables" as *;
@use "../mixins" as *;
@use "sass:math";

.grid {
  @include media-query($on-laptop) {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    // Replace `$spacing-unit /3` with `math.div($spacing-unit, 3)`
    grid-column-gap: math.div($spacing-unit, 3);
    grid-row-gap: math.div($spacing-unit, 3);
  }

  @include media-query($bigscreen) {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto;
    grid-column-gap: math.div($spacing-unit, 3);
    grid-row-gap: math.div($spacing-unit, 3);
  }
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}
/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 33.333%;
}

.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  max-width: 100%;
}

.grid.imagegrid,
.project {
  display: block;

  .grid-item {
    float: left;
    width: 33%;
    max-width: auto;
    display: block;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .grid-item--widthhalf {
    width: 16.5%;
  }

  .grid-item--width2 {
    width: 66%;
  }

  .grid-item--width3 {
    width: 100%;
  }

  @media screen and (max-width: $sub-palm) {
    //display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-column-gap: math.div($spacing-unit, 3);
    grid-row-gap: math.div($spacing-unit, 3);

    .grid-item,
    .grid-sizer {
      width: 100%;
    }
  }
}
