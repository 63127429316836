@use "../helpers/index" as helpers;

@include helpers.u-keyframes(fadeIn) {
  0% {
    opacity: 0;
    @include helpers.u-transform(translateZ(0));
  }
  100% {
    opacity: 1;
  }
}