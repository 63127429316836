@use "../variables" as *;
@use "../mixins" as *;


/**
 * Ratios
 * Returns the ratio for specified dimensions.
 */

.iframe-container {
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  @include media-query($on-palm) {
    min-height: 540px;
  }
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
   box-sizing: border-box;
   .vp-center {
     display: block; //fixing vimeo's fix that broke my fix
   }
   
}

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
  padding-top: 75%;
}
