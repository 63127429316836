@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(pulsate) {
  0%, 100% {
    opacity: 1;
    @include helpers.u-transform(scale(1) translateZ(0));
  }
  50% {
    opacity: 0.85;
    @include helpers.u-transform(scale(v.$saf-pulsate-scale));
  }
}

@mixin pulsate($duration: 1.5s, $delay: 0s, $fill-mode: none, $count: 3) {
  @include helpers.u-animation(pulsate $count $duration $delay $fill-mode);
}
