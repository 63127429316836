@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(slideInLeft) {
  0% {
    opacity: 0;
    @include helpers.u-transform(translateX(v.$saf-translateX-distance * -10) translateZ(0));
  }
  65% {
    opacity: 1;
    @include helpers.u-transform(translateX(v.$saf-translateX-distance));
  }
  100% {
    @include helpers.u-transform(translateX(0));
  }
}

@mixin slideInLeft($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(slideInLeft $duration $delay $fill-mode);
}

@include helpers.u-keyframes(slideInRight) {
  0% {
    opacity: 0;
    @include helpers.u-transform(translateX(v.$saf-translateX-distance * 10) translateZ(0));
  }
  65% {
    opacity: 1;
    @include helpers.u-transform(translateX(v.$saf-translateX-distance * -1));
  }
  100% {
    @include helpers.u-transform(translateX(0));
  }
}

@mixin slideInRight($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(slideInRight $duration $delay $fill-mode);
}
