@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(sunrise) {
  0% {
    @include helpers.u-transform(rotate(0deg) translate(v.$saf-sunrise-arch) rotate(0deg));
  }
  100% {
    @include helpers.u-transform(rotate(180deg) translate(v.$saf-sunrise-arch) rotate(-180deg));
  }
}

@mixin sunrise($duration: 4s, $delay: 0s, $fill-mode: both, $count: 1, $timing: ease-in-out) {
  @include helpers.u-animation(sunrise $count $duration $delay $fill-mode $timing);
}
