@use "../variables" as v;

body.posts {
  .text-navigation {
    a.page-link.posts {
      background-color: v.$charcoal;
      color: v.$paper;
      font-weight: bold;
    }
  }
}

.posts {
  //background-color: rgba(0, 0, 0, 0.5);
  //border-radius: 10px;
  .post {
    h2 {
      margin-inline-start: v.$spacing-unit;
    }

    margin-bottom: v.$spacing-unit * 2;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 8px;
    overflow: auto;

    .more-link {
      float: right;
      margin-right: v.$spacing-unit;
      clear: both;
      &::after {
        content: "";
      }
    }

    p,
    ul {
      max-width: 820px;
      margin: auto;
    }

    img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      padding: 1em;
      box-sizing: border-box;
    }
  }
}

.post-content {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.post-container {
  width: 100%;

  figure {
    margin: 0;
  }

  pre {
    padding: 5px;
    box-sizing: border-box;
    max-width: 1050px;
    word-break: break-all;
    word-wrap: break-word;
  }

  .sketches {
    display: flex;
    flex-direction: column;

    iframe {
      display: inline-flex;
      flex: 1;
    }
  }
}

.post-navigation {
  margin-top: 2em;
  padding-top: 1em;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
