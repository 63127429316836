@use "../variables" as v;

body.closed_rooms {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-shadow: 1px 2px 0px #000000;

  background-color: v.$charcoal;

  color: v.$paper;

  font-size: 1em;

  * {
    box-sizing: border-box;
  }

  .closed_rooms-container {
    position: absolute;
    top: 50vh;
    z-index: 10;
    padding: 8vw;
    flex: 1;
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    scroll-margin-bottom: 20px;
  }

  h2 {
    font-size: smaller;
  }

  h3 {
    font-size: x-small;
    margin-bottom: 30px;
  }

  a {
    // Instead of #ccc, pick a variable or keep it:
    color: v.$slate;
  }

  summary {
    cursor: pointer;
  }

  details {
    padding-left: 1.5vw;
    display: block;
    line-height: 2em;
  }

  .grid-item {
    width: 100%;
  }

  video {
    width: 100%;
    position: fixed;
  }

  footer {
    img {
      width: 30px;
      float: right;
      display: block;
    }
  }
}
