@use "sass:math";
@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(teeter) {
  0%, 100% {
    @include helpers.u-transform(rotate(0deg) translateZ(0));
  }
  15% {
    @include helpers.u-transform(rotate(v.$saf-teeter-angle));
  }
  30% {
    @include helpers.u-transform(rotate(v.$saf-teeter-angle * -1));
  }
  45% {
    @include helpers.u-transform(rotate(math.div(v.$saf-teeter-angle, 2)));
  }
  60% {
    @include helpers.u-transform(rotate(math.div(v.$saf-teeter-angle, 2) * -1));
  }
  73% {
    @include helpers.u-transform(rotate(math.div(v.$saf-teeter-angle, 3)));
  }
  86% {
    @include helpers.u-transform(rotate(math.div(v.$saf-teeter-angle, 3) * -1));
  }
}

@mixin teeter($duration: 1s, $delay: 0s, $fill-mode: none, $count: 1) {
  // hack to fix jagged edges
  outline: 1px solid transparent;
  @include helpers.u-animation(teeter $duration $delay $fill-mode $count);
}
