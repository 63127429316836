@use "sass:math";
@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(shake) {
  0%, 100% {
    @include helpers.u-transform(translateX(0) translateZ(0));
  }
  15% {
    @include helpers.u-transform(translateX(v.$saf-shake-distance));
  }
  35% {
    @include helpers.u-transform(translateX(v.$saf-shake-distance * -1));
  }
  50% {
    @include helpers.u-transform(translateX(math.div(v.$saf-shake-distance, 2)));
  }
  65% {
    @include helpers.u-transform(translateX(math.div(v.$saf-shake-distance, 2) * -1));
  }
  85% {
    @include helpers.u-transform(translateX(math.div(v.$saf-shake-distance, 3)));
  }
}

@mixin shake($duration: 0.5s, $delay: 0s, $fill-mode: none, $count: 1) {
  @include helpers.u-animation(shake $duration $delay $fill-mode $count);
}
