@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(turnInDown) {
  0% {
    opacity: 0;
    @include helpers.u-transform(perspective(500px) rotateX( v.$saf-turn-angle * -1) translateZ(150px));
  }
  100% {
    opacity: 1;
    @include helpers.u-transform(perspective(800px) rotateX(0deg) translateZ(0));
    @include helpers.u-prefix(backface-visibility, hidden, webkit moz spec);
  }
}

@mixin turnInDown($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(turnInDown $duration $delay $fill-mode);
}

@include helpers.u-keyframes(turnInUp) {
  0% {
    opacity: 0;
    @include helpers.u-transform(perspective(500px) rotateX( v.$saf-turn-angle) translateZ(150px));
  }
  100% {
    opacity: 1;
    @include helpers.u-transform(perspective(800px) rotateX(0deg) translateZ(0));
    @include helpers.u-prefix(backface-visibility, hidden, webkit moz spec);
  }
}

@mixin turnInUp($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(turnInUp $duration $delay $fill-mode);
}
