@use "../helpers/index" as helpers;

@include helpers.u-keyframes(explode) {
  0% {
    opacity: 1;
    @include helpers.u-transform(translateZ(0));
  }
  100% {
    opacity: 0;
    @include helpers.u-transform(scale(5));
  }
}

@mixin explode($duration: 0.5s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(explode $duration $delay $fill-mode);
}
