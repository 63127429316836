@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(riseIn) {
  0% {
    opacity: 0;
    @include helpers.u-transform(translateY(v.$saf-drop-rise-distance) translateZ(0));
  }
  50% {
    opacity: 1
  }
}

@mixin riseIn($duration: 0.5s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(riseIn $duration $delay $fill-mode);
}
