
body.voice {
    .text-navigation {
      a.page-link.voice {
        background-color: #33312b;
        color: #f9f7f3;
        font-weight: bold;
      }
    }
}
