@use "../variables" as v; 
@use "../mixins" as mx; 

body.binom {
    background: -webkit-linear-gradient(left, #b53248 47.7%,#86d6cb 47.8%,#86d6cb 47.9%), url('../images/noise.svg');; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #b53248 47.7%,#86d6cb 47.8%,#86d6cb 47.9%), url('../images/noise.svg'); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    
    .words {
        display: flex;
    }

    p {
        width: 50%;
        padding: 20px;
        &:nth-child(2) {
            color: v.$charcoal;
          }
    }
}