@use "sass:math";
@use "sass:color";

@use "../variables" as *; 
@use "../mixins" as *; 


body.abyss{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  @media (prefers-color-scheme: dark) {
    color: $background-color;
    a {
      color: $background-color;
      &:visited {
        color: $background-color;
      }
    }
  }
  #defaultCanvas0 {
      position: fixed; 
      display: block;
      top: 0; 
      left: 0; 
      z-index: -1; 
      width: 100vw;
      height: 100vh;
      transform-origin: top left;
      overflow: hidden;
    }
    .content {
        position: relative;
        z-index: 1; 
        margin: auto;
        max-width: 80vw;

    }
    .text-navigation {
    .event {
      text-align: center;
      display: block;
      @include media-query($bigscreen) {
        @include popIn(300ms);
        position: relative;
        display: inline-block;
        width: auto;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 3px 3px 3px 3px;
      } 
    }
    @include media-query($bigscreen) {
      max-width: 780px;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: 300px 420px;
      grid-gap: 20px;
      padding: 20px;
      ul {
        height: 500px;
        width: auto;
      }
      .results {
      }
    }
  }
}
