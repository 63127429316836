@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(riseOut) {
  0% {
    opacity: 1;
    @include helpers.u-transform(translateZ(0));
  }
  100% {
    opacity: 0;
    @include helpers.u-transform(translateY(v.$saf-drop-rise-distance * -1));
  }
}

@mixin riseOut($duration: 0.5s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(riseOut $duration $delay $fill-mode);
}
