@use "../helpers/index" as helpers;

@include helpers.u-keyframes(newspaper) {
  0% {
    opacity: 0;
    @include helpers.u-transform(rotate(720deg) scale(0.33) translateZ(0));
  }
  100% {
    opacity: 1;
    @include helpers.u-transform(rotate(0deg) scale(1));
  }
}

@mixin newspaper($duration: 1s, $delay: 0s, $fill-mode: both, $count: 1, $timing: ease-in-out) {
  @include helpers.u-animation(newspaper $count $duration $delay $fill-mode $timing);
}
