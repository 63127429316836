
body.cenotaph {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    .player {
        margin: auto;
        width: 100%;
        height: 100%;
    }
    video {
        
    }
    ::cue(#phyrgian) { color: rgb(89, 0, 255); }

    ::cue(.de) {
        color: #000;
        background-color: #ffcc00;
     }
     ::cue(.en) {
        color: #fff;
        background-color: #00247d;
     }
     ::cue(.es) {
        color: #f1bf00;
        background-color: #aa151b;
     }
     ::cue(.ara) {
        color: #ffc4dd;
        background-color: #aa151b;
     }

}