@use "sass:math";
@use "../variables" as *;
@use "../mixins" as *;

sidebar {
  display: none;
  position: fixed;

  padding: math.div($spacing-unit, 2);
  box-sizing: border-box;
  @include media-query($on-palm) {
    display: block;
    max-width: 320px;
  }

  ul.results-container {
    padding-left: 20px;
  }

  img.logo {
    max-width: 310px;
  }

  li {
    a {
      padding: 2px;

      &:hover, 
      &.focus, 
      &.selected {
        border: none;
        background-color: $charcoal;
        color: $paper;

        @media (prefers-color-scheme: dark) {
          background-color: $paper;
          color: $charcoal;
        }
      }
    }
  }

  ol {
    margin: 0;
    height: 311px;
    min-height: 311px;
    overflow-y: hidden;
  }

  .text-navigation {

    width: 310px;
    justify-self: center;
    display: flex;
    flex-direction: column;
    align-self: center;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: $spacing-unit;
    input {
      line-height: 32px;
      padding: 2px;
      font-size: 16px;
      box-sizing: border-box;
      font-family: $base-font-family;
    }

    
  }

  svg.logo {
    width: auto;
    height: 120px;

    path {
      fill: $paper;
    }
  }



}
