
body.webdev {
    .grid {
        grid-template-columns: auto auto;
        .grid-item {
        flex: auto;
        height: auto;
        min-width: 240px;
        max-width: 900px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        }
    }
    .grid-sizer {
        display: none;
    }
    .grid-item {
        margin: 5px;
    }
}