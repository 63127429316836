@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(quiver) {
  0%{
    @include helpers.u-transform(translate3d(2px, 1px, 0) rotate(0deg));
  }
  10% {
    @include helpers.u-transform(translate3d(-1px, 2px, 0) rotate(v.$saf-quiver-angle));
  }
  20% {
    @include helpers.u-transform(translate3d(-3px, 0) rotate(v.$saf-quiver-angle * -1));
  }
  30% {
    @include helpers.u-transform(translate3d(0, 2px, 0) rotate(0deg));
  }
  40% {
    @include helpers.u-transform(translate3d(1px, -1px, 0) rotate(v.$saf-quiver-angle));
  }
  50% {
    @include helpers.u-transform(translate3d(-1px, 2px, 0) rotate(v.$saf-quiver-angle * -1));
  }
  60% {
    @include helpers.u-transform(translate3d(-3px, 1px, 0) rotate(0deg));
  }
  70% {
    @include helpers.u-transform(translate3d(2px, 1px, 0) rotate(v.$saf-quiver-angle));
  }
  80% {
    @include helpers.u-transform(translate3d(-1px, -1px, 0) rotate(v.$saf-quiver-angle * -1));
  }
  90% {
    @include helpers.u-transform(translate3d(2px, 2px, 0) rotate(0deg));
  }
  100% {
    @include helpers.u-transform(translate3d(1px, -2px, 0) rotate(v.$saf-quiver-angle));
  }

}

@mixin quiver($duration: 0.75s, $delay: 0s, $fill-mode: none, $count: infinite) {
  // hack to fix jagged edges
  outline: 1px solid transparent;
  @include helpers.u-animation(quiver $duration $delay $fill-mode $count);
}
