@use "sass:color";
@use "sass:math";
@use "../variables" as v;
@use "../mixins" as mx;

// If your popIn mixin is defined in saffron, you might also need:
// @use "saffron/saffron" as saff;
// Then call @include saff.popIn(...) if it isn't forwarded into mixins.

@keyframes DarkAnimation {
  0% { background-position: 49% 0%; }
  50% { background-position: 52% 100%; }
  100% { background-position: 49% 0%; }
}

@keyframes LightAnimation {
  0% { background-position: 49% 0%; }
  50% { background-position: 52% 100%; }
  100% { background-position: 49% 0%; }
}

body.index {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // If you have a variable for these colors, feel free to replace
  // this gradient with something like v.$my-gradient or do multiple stops.
  background: linear-gradient(180deg, #d1e6e1, #c6c6c6, #d9a971, #71c1d9);
  background-size: 800% 800%;
  animation: LightAnimation 30s ease infinite;
  height: 100vh;

  // Instead of $background-color (global), reference v.$background-color
  // if your bridging approach or library partial provides it globally.
  @media (prefers-color-scheme: dark) {
    color: v.$background-color;
    background: linear-gradient(180deg, #004533, #001145, #25877a);
    background-size: 600% 600%;
    animation: DarkAnimation 30s ease infinite;

    a {
      color: v.$background-color;
      &:visited {
        color: v.$background-color;
      }
    }
  }

  .text-navigation {
    .event {
      text-align: center;
      display: block;

      // Use your namespaced mixin calls
      @include mx.media-query(v.$bigscreen) {
        // If popIn was forwarded into mixins, this is correct:
        @include mx.popIn(300ms);
        // If not, you may need to call @include saff.popIn(300ms);
        // and `@use "saffron/saffron" as saff;` up top.
        position: relative;
        display: inline-block;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        border-radius: 3px;
      }
    }

    @include mx.media-query(v.$bigscreen) {
      max-width: 780px;
      margin-left: auto;
      margin-right: auto;
      display: grid;
      grid-template-columns: 300px 420px;
      grid-gap: 20px;
      padding: 20px;

      ul {
        height: 500px;
        width: auto;
      }
    }
  }
}
