input#lineinput-field {
    background-color: inherit;
    color: inherit;
}
label#lineinput-prefix {
    background-color: inherit;
    color: inherit;
}

#haven {
    width: 100%;
    input {
        font-family: inherit;
    }
    a {
        text-decoration: underline;
    }
}

