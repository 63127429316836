@use "../helpers/index" as helpers;

@include helpers.u-keyframes(fadeInZoom) {
  0% {
    opacity: 0;
    @include helpers.u-transform(scale(0) translateZ(0));
  }
  100% {
    opacity: 1;
    @include helpers.u-transform(scale(1));
  }
}

@mixin fadeInZoom($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(fadeInZoom $duration $delay $fill-mode);
}
