// _variables.scss
@use "sass:color";
@use "sass:math";

// Keep only variables, no CSS rules
$translucent-ocean: rgba(0, 0, 0, 0.6);
$dreamland: #00112a;
$mahogany: #262421;
$bohemia: #27618f;
$bluedream: #2985c4;
$lost-feelings: #fa6D13;
$marigold: #febd25;
$peach: #ff7979;
$mac-classic: #ece7d7;
$charcoal: #33312b;
$paper: #f9f7f3;
$slate: #8C8A87;
$pino: #57e9e2;

$spacing-unit: 30px;

$text-color:       $charcoal;
$background-color: $paper;
$brand-color:      $dreamland;

$grey-color:       $bohemia;
$grey-color-light: color.adjust($grey-color, $lightness: 40%);
$grey-color-dark:  color.adjust($grey-color, $lightness: -25%);

$content-width: 900px;

$sub-palm:   600px;
$on-palm:    900px;
$on-laptop:  1200px;
$bigscreen:  1600px;

//typography

$base-font-family: 'courier_prime_coderegular', Courier, monospace;
$bold-font-family: 'courier_prime_coderegular', Courier, monospace;
$headline-font-family: 'gt_pressura_regular', sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;
