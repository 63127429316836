@use "../variables" as v;


body.content-awareness {
  background-color: #112;
  .grid {
    display: inline-flex;
    flex-flow: column wrap;
    width: 100%;
    .grid-item {
      flex: auto;
       height: auto;
       min-width: 240px;
       max-width: 900px;
       width: 100%;
       margin-left: auto;
       margin-right: auto;
    }
  }
}
body.bricolage, body.imageboard, body.photos, body[class*="photos"] {
  .container {
    padding: 0;
    box-sizing: border-box;
    max-width: 1200px;
    width: 100% !important;
  }

  @media screen and (max-width: 800px) {
    .container {
      max-width: 100%;
      .grid-item {
        width: 100%;
      }
    }
  }

}

.lazyloading {
  background: #f7f7f7 url(//afarkas.github.io/lazysizes/assets/imgs/loader.gif) no-repeat center;
  min-height: 60px;
}

body.photoboard .container {
  @media screen and (min-width: 900px) {
    margin-left: 165px;
  }
}

body.imageboard {

  .next {
    float: right;
    margin: 10px;
    &:after {
      font-family: "Font Awesome 5 Free", FontAwesome;
      content: "\f061";
      font-weight: 900;
      float: right;
      padding-left: 10px;
    }
  }

  .home {
    float: left;
    margin: 10px;
    &:after {
      font-family: "Font Awesome 5 Free", FontAwesome;
      content: "\f015";
      font-weight: 900;
      float: right;
      padding-left: 10px;
    }
  }

  .images-nav {
    padding: 20px;
  }

}