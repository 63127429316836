@use "../helpers/index" as helpers;
@use "../variables" as v;

@include helpers.u-keyframes(fadeOutDown) {
  0% {
    opacity: 1;
    @include helpers.u-transform(translateZ(0));
  }
  100% {
    opacity: 0;
    @include helpers.u-transform(translateY(v.$saf-translateY-distance));
  }
}

@mixin fadeOutDown($duration: 1s, $delay: 0s, $fill-mode: forwards) {
  @include helpers.u-animation(fadeOutDown $duration $delay $fill-mode);
}
