
body.design-manifestor {
    background-color: #fff;
    color: #000;
    .container {
        margin: auto;
        max-width: 600px;
    }
    button {
        font-size: 40px;
        background-color: #f00;
        color: #000;
    }
}