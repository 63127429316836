@use "../variables" as v;

body.contact {
  .text-navigation {
    a.page-link.contact {
      background-color: #33312b;
      color: #f9f7f3;
      font-weight: bold;
    }
  }
  .icon {
    svg, img {
      width: 20px;
    }
  }
  .container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
    > * {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
